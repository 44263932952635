import Dispatch from '../../../architecture/Dispatch'

import { Subject } from 'rxjs'
import {  withLatestFrom, mergeMap } from 'rxjs/operators'

export default (Auth) => {

  const requestCode = (email) => 
    {
        let state = new Subject()
            Auth.forgotPassword(email).then(data => {
                state.next({data, error: null, state: 'success', email: email })
            }).catch(err => state.next({ data: null, error: err, state: 'error', email: email }))
        return state
    }
  
  const resetPassword = (email, code, password) => {
    let state = new Subject()
            Auth.forgotPasswordSubmit(email, code, password).then(data => {
                state.next({data, error: null, state: 'success', email: email })
            }).catch(err => state.next({ data: null, error: err, state: 'error', email: email }))
        return state
  }

  Dispatch.getAction2('auth.forgotPassword.requestCode')
    .pipe(
      withLatestFrom(Dispatch.getState('Textbox.auth.forgotPassword.email')),
      mergeMap(([, email]) => requestCode(email))
    )
    .subscribe(e => {
      Dispatch.mergeState
      Dispatch.nextState('forgotPassword', {
          email: e.email,
          submitState: e.state,
          error: e.error,
        })
      if (e.state === 'success'){
        Dispatch.nextAction2('auth.resetPassword')
      }
    })

  Dispatch.getAction2('auth.resetPassword')
    .pipe(
      withLatestFrom(Dispatch.getState('forgotPassword.email'), Dispatch.getState('Textbox.auth.forgotPassword.code'), Dispatch.getState('Textbox.auth.forgotPassword.password')),
      mergeMap(([, email, code, password]) => resetPassword(email, code, password))
    )
    .subscribe(e => {
      Dispatch.mergeState('auth.forgotPassword', {
          submitState: e.state,
          error: e.error,
        })
      if (e.state === 'success'){
        Dispatch.nextAction('auth', 'do', 'signOut')
      }
    })


}