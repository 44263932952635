import Dispatch from '../../architecture/Dispatch'
import _ from 'lodash'

import { from, of, merge, combineLatest, timer } from 'rxjs'
import { map, mergeMap, switchMap, startWith, scan, groupBy, withLatestFrom, distinctUntilChanged, retryWhen, delayWhen, debounceTime } from 'rxjs/operators'

export default (client, type, variables, query, mutation, queryMapping, mutationMapping) => {
  return merge(
    Dispatch.getState(type+'.paginate')
      .pipe(
        switchMap(page => merge(of(1), Dispatch.getAction(type, 'refresh'))//timer(0, 10000)
          .pipe(
            switchMap(() => combineLatest(
                from(client
                  .query({ query, variables: variables ? { input: {...variables.input, ...page }}  : { input: { ...page } }, }))
                  .pipe(
                    //Does not actually retry the query (but does catch the error)
                    retryWhen(errors => errors
                      .pipe(
                        scan((acc,next) => {return acc + 1}, 1),
                        delayWhen(e => timer(2000 * e))
                      )
                    ),
                    map(e => queryMapping(e)),
                    // FIXME THIS NEEDS TO BE FIXED. SHOULD BE LISTENED TO BEFORE THE QUERY. OTHERWISE YOU CAN LOSE MUTATIONS IN BETWEEN THE TIME
                    // THE QUERY IS SENT AND THE TIME THAT THE QUERY RETURNS.
                    // mergeMap(raw => merge(
                    //   of(null),
                    //   Dispatch.getAction(type, 'mutated'),
                    //   Dispatch.getAction(type, 'mutatedExternal')
                    //   )
                    //   .pipe(
                    //     scan((acc, next) => {
                    //       let returnValue = acc
                    //       if (acc !== 'loading' && next !== null) {
                    //         const newRaw = [...acc]
                    //         const index = _.findIndex(newRaw, arr => arr.id === next.id)
                    //         newRaw[index] = next.response
                  
                    //         returnValue = newRaw
                    //       }
                    //       return returnValue
                    //     }, raw)
                    //   )
                    // )
                ),
                merge(
                    Dispatch.getAction(type, 'mutated'),
                    Dispatch.getAction(type, 'mutatedExternal')
                  )
                  .pipe(
                    scan((acc, next) => ({...acc, [next.id]: next.response}), {}),
                    startWith({})
                  )
                  
                )
                .pipe(
                  map(([raw, mutations]) => {
                    const tempRaw = [...raw]
                    Object.keys(mutations).forEach(key => {
                      //could be -1 if no found, badd timse
                      const index = _.findIndex(tempRaw, arr => arr.id === key)
                      tempRaw[index] = mutations[key]
                    })
                    return tempRaw
                  })
                )
            ),
            map(e => () => Dispatch.mergeState(type, { raw: e })),
          )
        )
      ),
      
    //Mutate
    Dispatch.getAction(type, 'save')
      .pipe(
        groupBy(id => id),
        mergeMap(group => group
          .pipe(
            withLatestFrom(Dispatch.getState(type + '.buffer.' + group.key)),
            map(([id, buffer]) => ({ id, buffer })),
            //Prevents saves if nothing has changed
            distinctUntilChanged((prev, next) => _.isEqual(prev.buffer, next.buffer)),

            switchMap(changes => from(client.mutate({
              mutation: mutation,
              variables: { id: changes.id, ...changes.buffer },
              }))
              .pipe(
                map(e => mutationMapping(e)),
                map(e => () => Dispatch.nextAction(type, 'mutated', { id: changes.id, response: e, request: changes.buffer })),
              )
            ),
          )
        )
      ),

    //Buffer
    merge(
      Dispatch.getAction(type, 'update')
        .pipe(
          map(e => acc => ({...acc, [e.id]: { ...acc[e.id], ...e.changes }}))
        ),
        //Set to undefined? null??
      // Dispatch.getAction('delete')
      //   .pipe(
      //     map(e => acc => { 
      //       const temp = {...acc}
      //       delete temp[id]
      //       return temp
      //     })
      //   ),
      Dispatch.getAction(type, "mutated")
        .pipe(
          map(e => acc => {
            let returnValue = {}
            const newAcc = {...acc }
            const buffer = {...newAcc[e.id]}
            Object.keys(e.request).forEach(item => {
              delete buffer[item]
            })
            if (Object.keys(newAcc).length !== 0) {
              returnValue = { ...newAcc, [e.id]: buffer }
            }
            return returnValue
          })
        )
    )
    .pipe(
      scan((acc, next) => { return next(acc) }, {}),
      map(e => () => Dispatch.mergeState(type, { buffer: e }  ))
    ),


    //Current
    combineLatest(Dispatch.getState(type + '.raw'), Dispatch.getState(type + '.buffer'))
          .pipe(
            debounceTime(1),
            map(([raw, buffer]) => {
              let returnValue = 'loading'
              if (raw !== 'loading') {
                const newRaw = [...raw]
                Object.keys(buffer).forEach(key => {
                  //could be -1 if no found, badd timse
                  const index = _.findIndex(newRaw, arr => arr.id === key)
                  newRaw[index] = {...newRaw[index], ...buffer[key] }

                })
                returnValue = newRaw
              }
              return returnValue
            }),
          
        map(e => () => Dispatch.mergeState(type, { current: e }))
      ),

  )
    .pipe(
      startWith(() => Dispatch.mergeState(type, { buffer: {}, raw: 'loading', current: 'loading' }))
    )
}
