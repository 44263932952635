import React from 'react';
import Header from './Header'

export default (props) => {
  return (
    <div>
      <Header headerText="You're already logged in!" />
      <div 
        className='pa3 b--light-gray br2'
        onClick={e => props.Dispatch.nextAction('auth', 'do', 'signOut')}>
        Logout
      </div>
    </div>
  )
}