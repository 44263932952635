import React from 'react';

import SignIn from './SignIn'
import SignedIn from './SignedIn'
import ChangePassword from './ChangePassword'
import { ForgotPassword, ResetPassword } from './ForgotPassword'

const objects = {
    showSignIn: SignIn,
    showSignedIn: SignedIn,
    showChangePassword: ChangePassword,
    showForgotPassword: ForgotPassword,
    showResetPassword: ResetPassword,
}

const Auth = (props) => {
    const Component = objects[props.auth && props.auth.state]
    return (
        <div>
            { Component ?
                <div className='mh3 mh4-ns mv3 ph3 pv3 avenir'>
                    <Component {...props} />
                    
                </div>
            :
            null
            }
        </div>
    )
}


export default Auth