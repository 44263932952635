import _ from 'lodash'
import { map, share } from 'rxjs/operators'
import { filter, observeOn, delay } from 'rxjs/operators'
import { queueScheduler, Observable } from 'rxjs'

export default () => {
  let dispatch;

  const update$ = Observable.create(observer => {
    dispatch = action => observer.next(action)
  })
    .pipe(
      // Forces every value to get rendered before next value is calculated
      observeOn(queueScheduler),
      // Delay allows state to update in between the dispatches. 
      // If dispatch is able to continuously dispatch, a bug can occur where the lateststate does not update in between which can causing overriding of state values
      delay(1),
      share(),
    )

  update$.subscribe((event) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(
        `%cDispatch:`,
        'background: green; color: white'
      )
      console.log(event)
    }
  });

  const checkId = (id, updateId) => {
    if (!_.isNil(id)) {
      const idTags = id.split('.')
      const updateTags = updateId.split('.')
      return idTags.reduce((acc, next, index) =>
        acc ? updateTags[index] === next || next === '*' : false, true)
    }
    else {
      return EMTPY
    }
  }

  // export const getId = (id) => update$
  //   .pipe(
  //     filter(update => checkId(id, update.id)),
  //     map(e => e.id)
  //   )

  const getAction = (id, action) => update$
    .pipe(
      filter(update => {
        const exists = checkId(id, update.id)
        return action ? exists && update.action === action : exists
      }
      ),
      map(e => e.value)
    )

  // export const getActionWithMeta = (id, action) => update$
  // .pipe(
  //   filter(update => {
  //     const exists = checkId(id, update.id)
  //     return action ? exists && update.action === action : exists
  //   }
  //   )
  // )

  const nextAction = (id, action, value) =>
    dispatch({id: id, action: action, value: value})



  const getAction2 = (listener) => {
    if (process.env.NODE_ENV !== 'production' && _.isNil(listener)) {
      console.log("ID IS UNDEFINED IN GETACTION2")
    }
    const id = listener ? listener : ''
    return update$
      .pipe(
        filter(update => {
          const unstarredId = id.replace('.*', '')
          return update.id.indexOf(unstarredId) === 0
        }),
        map(update => {
          const starred = id.substring(id.length-2, id.length) === '.*'
          if (!starred) {
            return update.value
          } 
          else 
          {
            const unstarredId = id.replace('.*', '')
            let updatedId = update.id.replace(unstarredId, '')
            if (updatedId.substring(0, 1) === '.') {
              updatedId = updatedId.substring(1, updatedId.length)
            }
            return {id: updatedId, value: update.value}
          }
        })
      )
    }

  const nextAction2 = (id, value) =>
    dispatch({id: id, value: value})

  return {
    nextAction,
    getAction,
    getAction2,
    nextAction2,
  }
}