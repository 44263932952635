import { from, of, queueScheduler, Observable } from 'rxjs';

import _ from 'lodash'
import { filter, map, tap, scan, distinctUntilChanged, mergeMap, groupBy,
  publishReplay, refCount, observeOn, startWith } from 'rxjs/operators'

export default () => {
  //import StartingValues from '../StartingValues'
  const setObject = (object, keypath, value) => {
    const tempObject = _.cloneDeep(object)
    const oldValue = _.get(tempObject, keypath)
    const oldValueFixed = oldValue ? oldValue : {}
    return _.set(tempObject, keypath, { ...oldValueFixed, ...value })
  }

  let dispatch;

  const log = (text) => {
    if (process.env.NODE_ENV !== 'production') {
      if (text === 'store') {
        console.log(
          `%cStore:`,
          'background: red; color: white'
        )
      }
      else if (text === 'update') {
        console.log(
          `%cUpdate:`,
          'background: blue; color: white'
        )
      }
      else {
        console.log(text)
      }
    }
  }

  const update$ = Observable.create(observer => {
    dispatch = action => observer.next(action)
  })
    .pipe(
      // Forces every value to get rendered before next value is calculated
      observeOn(queueScheduler)
    )

  const Store = update$
    .pipe(
      tap(e => process.env.NODE_ENV !== 'production' ? log('update') : null),
      tap(e => process.env.NODE_ENV !== 'production' ? log(e({})) : null),
      scan((acc, next) => next(acc), {}),
      distinctUntilChanged((a, b) => _.isEqual(a, b)),
      tap(e => process.env.NODE_ENV !== 'production' ? log('store') : null),
      tap(e => process.env.NODE_ENV !== 'production' ? log(e) : null),
      startWith({}),
      publishReplay(1),
      refCount(),
    )

  Store.subscribe((event) => {
    if (process.env.NODE_ENV !== 'production') {

    }
  });

  const getState = (keypath = null) => {
    if (_.isNil(keypath)) {
      return Store
    }
    else {
      return Store
        .pipe(
          mergeMap(e => {
            const unstarredId = keypath.replace('.*', '')
            const value = _.get(e, unstarredId)
            if (keypath.substring(keypath.length-2, keypath.length) === '.*'){
              return from(Object.keys(value).map(e => ({ id: e, value: { id: e, value: value[e] } })))
            }
            else {
              return of({ id: null, value })
            }
          }),
          groupBy(e => e.id),
          mergeMap(group => group
            .pipe(
              map(object => object.value),
              distinctUntilChanged((a, b) => _.isEqual(a, b))
            )
          ),
          filter(e => !_.isNil(e) )
        )
      }
    }

    const getStateUndefined = (keypath = null) => {
      if (_.isNil(keypath)) {
        return Store
      }
      else {
        return Store
        .pipe(
          mergeMap(e => {
            const unstarredId = keypath.replace('.*', '')
            const value = _.get(e, unstarredId)
            if (keypath.substring(keypath.length-2, keypath.length) === '.*'){
              return from(Object.keys(value).map(e => ({ id: e, value: { id: e, value: value[e] } })))
            }
            else {
              return of({ id: null, value })
            }
          }),
          groupBy(e => e.id),
          mergeMap(group => group
            .pipe(
              map(object => object.value),
              distinctUntilChanged((a, b) => _.isEqual(a, b))
            )
          ),
        )
        }
      }

  const nextState = (key, value) => {
    return dispatch( (acc) => _.set( _.cloneDeep(acc), key, value))
  }

  const mergeState = (key, value) =>
    dispatch( (acc) => setObject(acc, key, value))
    //_.cloneDeep({...acc, [key]: { ...acc[key], ...value }}))

  // keypath.split('.').reduce((acc, next) => ({...acc, [next]: acc[next] ? {...acc[next]} : {} }))
  //scan((acc, next) => ({...acc, [next.key]: _.merge({}, acc[next.key], { [next.id]: next.value })}), {}),
  return {
    mergeState,
    nextState,
    getStateUndefined,
    getState
  }
}
