import React from 'react';
import loadingIcon from '../../media/loading.svg'

export default (props) => {
    let ToRender = NormalState
    if (props.targetState === 'pending') {
        ToRender = PendingState
    }
    else if (props.targetState === 'success') {

    }
    else if (props.targetState === 'error') {
        ToRender = ErrorState
    }
   return (
       <div>
           <ToRender {...props} />
       </div>
   )
}

const NormalState = (props) => {
    return (
        <div
            className={'bg-blue white tc center ' + props.css}
            onClick={e =>
                props.handleSubmit()
            }
            > { props.text }
        </div>
    )
}

const ErrorState = (props) => {
    return (
        <div
            className={'tc center white bg-red ' + props.css}
            onClick={e =>
                props.handleSubmit()
            }
            > Error
        </div>
    )
}

const PendingState = (props) => {
    return (
        <div className='pv3 w3 center'>
            <img className='w-100' src={loadingIcon}  alt="Logo" />
        </div>
    )
}
