import React from 'react'
import Dispatch from '../../architecture/Dispatch'
import BaseComponent from '../../architecture/BaseComponent'
import { of } from 'rxjs'
import { map, switchMap, take, delay } from 'rxjs/operators'
import _ from 'lodash'

const getDispatchIds = (id) => {
  const idArray = id.split('.')
  let idString = idArray.reduce((acc, next, index) => index + 1 !== idArray.length ? acc + '.' + next : acc, '')
  idString = idString ? idString : ''
  return {
    idString,
    idValue: idArray[idArray.length - 1]
  }
}

Dispatch.getAction('Textbox', 'remove')
  .pipe(
    map(e => getDispatchIds(e)),
    switchMap(ids => Dispatch.getState('Textbox' + ids.idString)
      .pipe(
        take(1),
        map((state) => {
          const { [ids.idValue]: value,...newState } = state
          return newState
        }),
        map(newState => ({ids, state: newState}))
    )),
  )
  .subscribe(e => {
    Dispatch.nextState('Textbox' + e.ids.idString, e.state)
    Dispatch.nextState('TextboxIds' + e.ids.idString, e.state)
  })


const types = {
  tel: 'tel',
  zip: 'text',
  text: 'text',
  email: 'email',
  checkbox: 'checkbox',
  password: 'password',
}

const parseType = (type, value) => {
  let newValue = value
  if (type === 'zip') {
    const tempValue = value.replace(/\D+/g, '');
    newValue = tempValue.substring(0,5)
  }
  // if (type === 'phone') {
  //   let tempValue = value.replace(/\D+/g, '');
  //   if (tempValue.substring(0,1) === '1') {
  //     tempValue = tempValue.substring(1,10)
  //   }
  //   const areaCode = tempValue.substring(0,3)
  //   const next3 = tempValue.substring(3,6)
  //   const next4 = tempValue.substring(6,10)
  //   newValue = 
  // }
  return newValue
}

class Textbox extends React.Component {
  componentDidMount() {
    //const resetOnMount = this.props.resetOnMount ? this.props.resetOnMount : false
    const defaultValue = this.props.defaultValue
    const dispatchIds = getDispatchIds(this.props.id)
    const substituteValue = types[this.props.type] === 'checkbox' ? false : '' 
    if (_.isNil(this.props.value)){
      Dispatch.mergeState('Textbox' + dispatchIds.idString, { [dispatchIds.idValue]: defaultValue ? defaultValue : substituteValue })
    }
    Dispatch.mergeState('TextboxIds' + dispatchIds.idString, { [dispatchIds.idValue]: true})
    // if (resetOnMount){
    //   Dispatch.mergeState('Textbox' +  dispatchIds.idString, { [dispatchIds.idValue]: defaultValue ? defaultValue : '' })
    // }
  }
  componentWillUnmount() {
    //Should return all the selects minus this id (to keep from memory leaking)
    Dispatch.nextAction('Textbox', 'remove', this.props.id)
  }
  render() {
    if (!this.props.id) {
      throw "Textbox missing ID. ID is required."
    }
    //console.log(props)
    const id = this.props.id
    
    const className = this.props.className
    const value = this.props.value
    const style = this.props.style
    const onKeyDown = this.props.onKeyDown
    const onBlur = this.props.onBlur
    const onChange = this.props.onChange
    const placeholder = this.props.placeholder
    const type = this.props.type ? this.props.type : 'text'

    const dispatchIds = getDispatchIds(id)
    return (
      <input 
        type={types[type] ? types[type] : 'text'}
        key={id}
        style={style}
        value={value} 
        placeholder={placeholder}
        className={className} 
        onBlur={e => onBlur ? onBlur(e) : null}
        onKeyDown={ e => onKeyDown ? onKeyDown(e) : null } 
        onChange={ e => {
          const newValue = (type === 'checkbox') ? e.target.checked : e.target.value 
          const update = parseType(type, newValue)
          Dispatch.nextAction('Textbox.' + id, 'update', update )
          Dispatch.mergeState('Textbox' + dispatchIds.idString, { [dispatchIds.idValue]: update })
          of(1).pipe(delay(200)).subscribe(e => Dispatch.nextAction('window', 'resize'))
        }}/>
    )
  }
}


export default (props) => <BaseComponent 
  Component={Textbox} 
  store={props.stream ? 
    props.stream 
    : 
    Dispatch.getState('Textbox.' + props.id)
      .pipe(
        map(e => ({ value: e }))
      )
  } {...props}/>