import { Subject } from 'rxjs';
import { scan,
  startWith, distinctUntilChanged, publishReplay, refCount } from 'rxjs/operators';
import _ from 'lodash'

export default (initValue = {}) => {
    const subject = new Subject()

    const list = subject
        .pipe(
            scan((acc,next) => next(acc), initValue),
            startWith(initValue),
            distinctUntilChanged((a, b) => _.isEqual(a, b)),
            publishReplay(1),
            refCount()
        )
    
    // Subscribe to prevent lost values due to call order
    list
        .subscribe()

    const update = (value) =>
        subject.next((acc) => ({ ...acc, ...value }))

    const remove = (id) => 
        subject.next(
            (acc) => _.omit(acc, [id]))

    const clear = () => subject.next(() => initValue)

    return {
        update,
        remove,
        clear,
        list
    }
}