import React from 'react'
import _ from 'lodash'
import Dispatch from '../../../architecture/Dispatch'
import Header from './Header'
import Footer from './Footer'

const NewPasswordInput = (props) => (
    <div className='mt2'>
        <label className="f6 fw4 mb2 mt2">New Password * </label>
        <input
            className="input-reset ba b--black-20 pa3 mt2 mb2 w-100 br2"
            type="password"
            aria-describedby="name-desc"
            defaultValue={props.changePassword.attributes.password}
            placeholder='Password'
            onChange={e => Dispatch.nextAction('changePassword', 'update', {
                type: 'password',
                value: e.target.value
            })}
        />
    </div>
)

const NameInput = (props) => (
    <div className='mt2'>
        <label className="f6 fw4 mb2 mt2">Name * </label>
        <input
            className="input-reset ba b--black-20 pa3 mt2 mb2 w-100 br2"
            type="text"
            aria-describedby="name-desc"
            defaultValue={props.changePassword.attributes.name}
            placeholder='Name'
            onChange={e => Dispatch.nextAction('changePassword', 'update', {
                type: 'name',
                value: e.target.value
            })}
        />
    </div>
)

const PictureInput = (props) => (
    <div className='mt2'>
        <label className="f6 fw4 mb2 mt2">Picture URL * </label>
        <input
            className="input-reset ba b--black-20 pa3 mt2 mb2 w-100 br2"
            type="text"
            aria-describedby="name-desc"
            defaultValue={props.changePassword.attributes.picture}
            placeholder='Picture URL'
            onChange={e => Dispatch.nextAction('changePassword', 'update', {
                type: 'picture',
                value: e.target.value
            })}
        />
    </div>
)

const OrganizationInput = (props) => (
    <div className='mt2'>
        <label className="f6 fw4 mb2 mt2">Organization * </label>
        <input
            className="input-reset ba b--black-20 pa3 mt2 mb2 w-100 br2"
            type="text"
            aria-describedby="name-desc"
            defaultValue={props.changePassword.attributes.organization}
            placeholder='Picture URL'
            onChange={e => Dispatch.nextAction('changePassword', 'update', {
                type: 'organization',
                value: e.target.value
            })}
        />
    </div>
)

const PhoneInput = (props) => (
    <div className='mt2'>
        <label className="f6 fw4 mb2 mt2">Telephone Number * </label>
        <input
            className="input-reset ba b--black-20 pa3 mt2 mb2 w-100 br2"
            type="tel"
            aria-describedby="name-desc"
            defaultValue={props.changePassword.attributes.phone_number}
            placeholder='123-456-7890'
            onChange={e => Dispatch.nextAction('changePassword', 'update', {
                type: 'phone_number',
                value: '+1' + e.target.value.replace(/\-/g, '')
            })}
        />
    </div>
)

export default (props) => {
  const response = props.changePassword && props.changePassword.response
  const attributes = props.changePassword && props.changePassword.attributes ? props.changePassword.attributes : {}
  const pendingState = {
      submit: () => Dispatch.nextAction('changePassword', 'submit'),
      submitState: props.changePassword && props.changePassword.response &&
          props.changePassword.response.submitState,
      error: response && response.error && 
          response.error.message,
      buttonText: 'Set Password'
  }
  return (
    <div>
        <Header headerText='Account Setup'/>
        { _.isNil(attributes.name) ? null : NameInput(props) }
        { _.isNil(attributes.organization) ? null : OrganizationInput(props) }
        { _.isNil(attributes.phone_number) ? null : PhoneInput(props) }
        { _.isNil(attributes.picture) ? null : PictureInput(props) }
        { _.isNil(attributes.password) ? null : NewPasswordInput(props) }
        <Footer pendingState={pendingState} />
    </div>
  )
}