import React from 'react';
import Dispatch from '../../../architecture/Dispatch'
import Header from './Header'
import Footer from './Footer'
import Textbox from '../../textbox/Textbox'

export const ForgotPassword = (props) => {
    const response = props.auth.forgotPassword && props.auth.forgotPassword.response
    const pendingState = {
        submit: () => Dispatch.nextAction2('auth.forgotPassword.requestCode', ''),
        submitState: response &&
            response.submitState,
        error: response && response.error &&
            response.error.message,
        buttonText: 'Request Code'
    }
  return (
    <div>
        <Header headerText='Password Reset'/>
        <label className="f6 fw4 mb2 mt2">Email *
            {/* <div className="f6 normal black-60"> (optional)</div> */}
            </label>
            <Textbox
              id='auth.forgotPassword.email'
              className="input-reset ba b--black-20 pa3 mt2 mb3 w-100 br2"
              defaultValue={props.signIn.username}
              placeholder='Enter your email'
              //onChange={e => Dispatch.nextAction2('auth.forgotPassword.email', {username: e.target.value})}
            />
            {/* <input
                className="input-reset ba b--black-20 pa3 mt2 mb3 w-100 br2"
                type="text"
                aria-describedby="name-desc"
                defaultValue={props.signIn.username}
                placeholder='Enter your email'
                onChange={e => Dispatch.nextAction2('auth.forgotPassword.email', {username: e.target.value})}
            /> */}
        <Footer pendingState={pendingState} />
    </div>
  )
}

export const ResetPassword = (props) => {
  const response = props.signIn && props.signIn.response
  const pendingState = {
      submit: () => Dispatch.nextAction2('auth.resetPassword', ''),
      submitState: props.signIn && props.signIn.response &&
          props.signIn.response.submitState,
      error: response && response.error &&
          response.error.message,
      buttonText: 'Change Password'
  }
return (
  <div>
      <Header headerText='Password Reset'/>
      <label className="f6 fw4 mb2 mt2">Code *
          {/* <div className="f6 normal black-60"> (optional)</div> */}
          </label>
          <Textbox
              id='auth.forgotPassword.code'
              className="input-reset ba b--black-20 pa3 mt2 mb3 w-100 br2"
              placeholder='Enter the code sent to your email'
              //onChange={e => Dispatch.nextAction2('auth.forgotPassword.email', {username: e.target.value})}
            />
        <label className="f6 fw4 mb2 mt2">New Password *
          {/* <div className="f6 normal black-60"> (optional)</div> */}
          </label>
          <Textbox
            type='password'
              id='auth.forgotPassword.password'
              className="input-reset ba b--black-20 pa3 mt2 mb3 w-100 br2"
              placeholder='Enter your new password'
              //onChange={e => Dispatch.nextAction2('auth.forgotPassword.email', {username: e.target.value})}
            />
      <Footer pendingState={pendingState} />
  </div>
)
}