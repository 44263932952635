import React from 'react';
import Dispatch from '../../../architecture/Dispatch'
import Header from './Header'
import Footer from './Footer'

export default (props) => {
    const response = props.signIn && props.signIn.response
    const pendingState = {
        submit: () => Dispatch.nextAction('signIn', 'submit'),
        submitState: props.signIn && props.signIn.response &&
            props.signIn.response.submitState,
        error: response && response.error &&
            response.error.message,
        buttonText: 'Sign In'
    }
  return (
    <div>
        <Header headerText='Sign in to your account'/>
        <label className="f6 fw4 mb2 mt2">Email *
            {/* <div className="f6 normal black-60"> (optional)</div> */}
            </label>
            <input
                className="input-reset ba b--black-20 pa3 mt2 mb3 w-100 br2"
                type="text"
                aria-describedby="name-desc"
                defaultValue={props.signIn.username}
                placeholder='Enter your email'
                onChange={e => Dispatch.nextAction('signIn', 'update', {username: e.target.value})}
            />
        <label className="f6 fw4 mb2 mt2">Password *
            {/* <div className="f6 normal black-60"> (optional)</div> */}
            </label>
            <input
                className="input-reset ba b--black-20 pa3 mt2 mb2 w-100 br2"
                type="password"
                aria-describedby="name-desc"
                defaultValue={props.signIn.password}
                placeholder='Enter your password'
                onChange={e => Dispatch.nextAction('signIn', 'update', {password: e.target.value})}
            />
            <div
                className='f7 gray'>
                Forgot your password? <span className='pointer blue fw6' onClick={e => Dispatch.nextAction2('auth.forgotPassword')}> Reset password </span>
            </div>
        <Footer pendingState={pendingState} />
    </div>
  )
}
