import Storer from './private/Store'
import Dispatcher from './private/Dispatcher'

export default () => {
  const Store = Storer()
  const Dispatch = Dispatcher()
  return {
    nextState: Store.nextState,
    mergeState: Store.mergeState,
    getState: Store.getState,
    nextAction: Dispatch.nextAction,
    getAction: Dispatch.getAction,
    getStateUndefined: Store.getStateUndefined,
    getAction2: Dispatch.getAction2,
    nextAction2: Dispatch.nextAction2,
  }
}
