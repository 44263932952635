import AuthComponent from './components/Auth'
import authEngine from './engines/auth'

import changepw from './engines/changepw'
import signIn from './engines/signIn'
import forgotPassword from './engines/forgotPassword'

export default (Amplify, Auth) => {
  // Amplify.configure({
  //   Auth: {

  //       // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  //       identityPoolId: process.env.REACT_APP_AMPLIFY_USER_FEDERATED_IDENTIFY_POOL,
        
  //       // REQUIRED - Amazon Cognito Region
  //       region: process.env.REACT_APP_AMPLIFY_REGION,

  //       // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
  //       // Required only if it's different from Amazon Cognito Region
  //       //identityPoolRegion: 'XX-XXXX-X',

  //       // OPTIONAL - Amazon Cognito User Pool ID
  //       userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,

  //       // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  //       userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID_WEB_ID,

  //       // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  //       mandatorySignIn: false,

  //       // // OPTIONAL - Configuration for cookie storage
  //       // cookieStorage: {
  //       // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
  //       //     domain: '.yourdomain.com',
  //       // // OPTIONAL - Cookie path
  //       //     path: '/',
  //       // // OPTIONAL - Cookie expiration in days
  //       //     expires: 365,
  //       // // OPTIONAL - Cookie secure flag
  //       //     secure: true
  //       // },

  //       // OPTIONAL - customized storage object
  //       //storage: new MyStorage(),
        
  //       // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  //       //authenticationFlowType: 'USER_PASSWORD_AUTH'
  //   },
  // });

  authEngine(Auth)
  signIn(Auth)
  changepw(Auth)
  forgotPassword(Auth)
  return AuthComponent
}
