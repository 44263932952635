import Dispatch from '../../../architecture/Dispatch'
//import { Auth } from 'aws-amplify';
import { map } from 'rxjs/operators'
import { Subject, combineLatest } from 'rxjs'
import { switchMap, withLatestFrom } from 'rxjs/operators'

import listObject from '../../functions/listObject'
import submitState from '../../functions/submitState'

const name = 'signIn'

export default ( Auth ) => {

    const signin = (username, password) => 
    {
        let state = new Subject()
        Auth.signIn(username.toLowerCase(), password).then(data => {
            state.next({data, error: null, state: 'success' })
        }).catch(err => state.next({ data: null, error: err, state: 'error' }))
        return state
    }

    /*********************** Internal ***********************/

    const authForm = listObject({ username: '', password: '', })

    Dispatch.getAction(name, 'update')
        .subscribe(e => authForm.update(e))

    Dispatch.getAction(name, 'success')
        .subscribe(e => authForm.clear())

    /*********************** External ***********************/
    // Submit Form
    Dispatch.getAction(name, 'submit')
    .pipe(
        withLatestFrom(authForm.list),
        map(([submit, data]) => ({
            ...data,
        })),
        switchMap(e => {
            const signin$ = signin(e.username, e.password)
            const submitState$ = submitState(signin$.pipe(map(e => e.state)))

            return combineLatest(signin$, submitState$)
                .pipe(
                    map(([signin, submitState]) => 
                        ({ data: signin.data, error: signin.error, submitState }))
                )
            }
        )
    )
    .subscribe(e => {
        authForm.update( { response: e} )
        if (e.submitState === 'success') {
            Dispatch.nextAction(name, 'success', e)
        }
    })

    authForm.list.subscribe(e => Dispatch.nextState(name, e))

}