import Dispatch from '../../../architecture/Dispatch'

import { of, Subject, combineLatest } from 'rxjs'
import { scan, map, switchMap, withLatestFrom, filter, startWith, mergeMap, share } from 'rxjs/operators'
import submitState from '../../functions/submitState'

const name='changePassword'

const createStateObject = (data) => {
    const attributes = data.challengeParam.requiredAttributes.reduce((acc, next) => { 
        acc[next] = ''
        return acc
    }, { password: '' })
    return { data, attributes }
}

export default (Auth) => {

    const changePW = (data, newPassword, requiredAttributes) => 
    {
        let state = new Subject()
            Auth.completeNewPassword(data, newPassword, requiredAttributes).then(data => {
                state.next({data, error: null, state: 'success' })
            }).catch(err => state.next({ data: null, error: err, state: 'error' }))
        return state
    }

    //Update State
    const attributeState$ = Dispatch.getAction('auth', 'state')
        .pipe(
            filter(e => e.name === "showChangePassword"),
            switchMap(e => combineLatest( 
                        of(createStateObject(e.data)),
                        Dispatch.getAction(name, 'update'))
                .pipe(
                    map(([state, update]) => ({ state, update })),
                    scan((acc, next) => ({
                        ...acc,
                        ...next.state,
                        attributes: {
                            ...next.state.attributes,
                            ...acc.attributes,
                            [next.update.type]: next.update.value,
                        }
                    }), { }),
                    startWith(createStateObject(e.data))
                ),
            ),
            share()
        )

    // Submit Form
    const submitState$ = Dispatch.getAction(name, 'submit')
    .pipe(
        withLatestFrom(attributeState$),
        mergeMap(([submit, state]) => {
            const { password, ...second } = state.attributes
            const signin$ = changePW(state.data, state.attributes.password, second)
            const submit$ = submitState(signin$.pipe(map(e => e.state)))

            return combineLatest(signin$,submit$)
                .pipe(
                    map(([signin, submitState]) => 
                        ({ data: signin.data, error: signin.error, submitState }))
                )
            }
        ),
        share()
    )

    // Dispatch Success
    submitState$
        .subscribe(e => {
            if (e.submitState === 'success') {
                Dispatch.nextAction(name, 'success', e)
            }
        })

    // Dispatch State
    combineLatest(attributeState$, submitState$.pipe(startWith({})))
        .pipe(
            map(([attributes, submit]) => ({ response: submit, ...attributes }))
        )
        .subscribe(e => Dispatch.nextState(name, e))
}