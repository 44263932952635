import React from 'react';
import _ from 'lodash'
import PendingStateButton from '../../misc/PendingStateButton'

export default (props) => {
  return (

    <div className='pt3'>
      { !_.isNil(props.pendingState.error) ? 
          <div
              className='f5 red pt3'>
              Error: { props.pendingState.error }
          </div>
          :
          null
      } 
      <PendingStateButton 
          //targetState ='error'
          css='mt3 pa3 br2 tc white pointer grow'
          targetState={props.pendingState.submitState} 
          handleSubmit={e => props.pendingState.submit()} 
          text={props.pendingState.buttonText}
      />
    </div>
  )
}