import React from 'react';
//import BackArrow from './BackArrow'

export default (props) => {
  return (
    <div>
      {/* <BackArrow /> */}
      <div
        className='pt3 f4 f3-ns fw5 fw4-ns pb4'>
        { props.headerText }
      </div>
    </div>
  )
}