import React from 'react'
import Dispatch from '../../architecture/Dispatch'
import BaseComponent from '../../architecture/BaseComponent'
import { map, switchMap, take } from 'rxjs/operators'
import _ from 'lodash'
import Select from 'react-select';


const getDispatchIds = (id) => {
  const idArray = id.split('.')
  let idString = idArray.reduce((acc, next, index) => index + 1 !== idArray.length ? acc + '.' + next : acc, '')
  idString = idString ? idString : ''
  return {
    idString,
    idValue: idArray[idArray.length - 1]
  }
}

Dispatch.getAction('__.Select', 'remove')
  .pipe(
    map(e => getDispatchIds(e)),
    switchMap(ids => Dispatch.getState('__.Select' + ids.idString)
      .pipe(
        take(1),
        map((state) => {
          const { [ids.idValue]: value,...newState } = state
          return newState
        }),
        map(newState => ({ids, state: newState}))
    )),
  )
  .subscribe(e => {
    Dispatch.nextState('__.Select' + e.ids.idString, e.state)
   // Dispatch.nextState('SelectIds' + e.ids.idString, e.state)
  })

class SelectWrapper extends React.Component {
  componentDidMount() {
    //const resetOnMount = this.props.resetOnMount ? this.props.resetOnMount : false
    const defaultValue = this.props.defaultValue
    const dispatchIds = getDispatchIds(this.props.id)
    if (_.isNil(this.props.value)){
      Dispatch.mergeState('__.Select' + dispatchIds.idString, { [dispatchIds.idValue]: defaultValue ? defaultValue : '' })
    }
    // if (resetOnMount){
    //   Dispatch.mergeState('Select' +  dispatchIds.idString, { [dispatchIds.idValue]: defaultValue ? defaultValue : '' })
    // }
    // Update list of IDs
   // Dispatch.mergeState('SelectIds' + dispatchIds.idString, { [dispatchIds.idValue]: true})
  }
  componentWillUnmount() {
    //Should return all the selects minus this id (to keep from memory leaking)
    Dispatch.nextAction('__.Select', 'remove', this.props.id)
  }
  render() {
    //console.log(props)
    const id = this.props.id
    
    const className = this.props.className
    const value = this.props.value
    const style = this.props.style
    const onKeyDown = this.props.onKeyDown
    const onBlur = this.props.onBlur
    const onChange = this.props.onChange
    const placeholder = this.props.placeholder
    const defaultValue= this.props.defaultValue
    const menuPlacement = this.props.menuPlacement ? this.props.menuPlacement : 'auto'

    const dispatchIds = getDispatchIds(id)

    const options = this.props.options
    return (
      <Select className={className}
        options={options}
        // onBlur={e => of(1).pipe(delay(1)).subscribe(e => Dispatch.nextAction('window', 'resize'))}
        menuPlacement={menuPlacement}
        onChange={ e => {
          Dispatch.nextAction2('__.Select.update.' + id, e )
          Dispatch.mergeState('__.Select' + dispatchIds.idString, { [dispatchIds.idValue]: e})
          //of(1).pipe(delay(100)).subscribe(e => Dispatch.nextAction('window', 'resize'))
        }}
        value={value}>
      </Select>
    )
  }
}


export default (props) => <BaseComponent 
  Component={SelectWrapper} 
  store={props.stream ? 
    props.stream 
    : 
    Dispatch.getState('__.Select.' + props.id)
      .pipe(
        map(e => ({ value: e }))
      )
  } {...props}/>