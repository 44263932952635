import Dispatch from '../../../architecture/Dispatch'
import _ from 'lodash'
import { map, mergeMap, catchError, filter} from 'rxjs/operators'
import { of, from, merge } from 'rxjs'

import listObject from '../../functions/listObject'

export default ( Auth ) => {

    const getCurrentUser = () => from(Auth.currentAuthenticatedUser())
        .pipe(
            catchError(() => of(null))
        )

    // In charge of managing the user information and the current auth screen
    const name = 'auth'

    /*********************** Handlers ****************/

    Dispatch.getAction(name, 'do')
        .pipe(
            filter(e => e === 'signOut'),
            map(e => from(Auth.signOut()))
        )
        .subscribe(e => Dispatch.nextAction(name, 'state', { 
            name: 'showSignIn', user: null
        }))

    Dispatch.getAction2(name + '.forgotPassword')
        .subscribe(e => Dispatch.nextAction(name, 'state', { 
            name: 'showForgotPassword'
        }))

    Dispatch.getAction2(name + '.resetPassword')
        .subscribe(e => Dispatch.nextAction(name, 'state', { 
            name: 'showResetPassword'
        }))

    /*********************** Reducers ***********************/

    // Starting User
    getCurrentUser()
        .subscribe(e => {
            if (e) {
                Dispatch.nextAction(name, 'state', {
                    name: 'showSignedIn', user: e
                })
            }
            else {
                Dispatch.nextAction(name, 'state', {
                    name: 'showSignIn', user: null
                })
            }
        })

    Dispatch.getAction('auth', 'state')
        .pipe(
            map(e => _.isNil(e.user) ? null : e.user)
        )
        .subscribe(e => Dispatch.nextAction('auth', 'user', e))

    /**** Login success */
    const loginSuccessStream$ = merge(Dispatch.getAction('signIn', 'success'),
        Dispatch.getAction('changePassword', 'success'))

    loginSuccessStream$
        .pipe(
            filter(e => e.data.signInUserSession !== null),
            mergeMap(e => from(Auth.currentAuthenticatedUser())),
        )
        .subscribe(e => Dispatch.nextAction(name, 'state', { 
            name: 'showSignedIn', user: e
        }))

    // Auth form submit success, Set Force Change Password State
    loginSuccessStream$
        .pipe(
            filter(e => e.data.signInUserSession === null)
        )
        .subscribe(e => 
            Dispatch.nextAction(name, 'state', { 
                name: 'showChangePassword', data: e.data
            })
        )

    Dispatch.getAction('forgotPassword', 'success')
        .pipe(

        )
        
    /*********************** State ***********************/

    const auth = listObject({ 
        state: 'showSignIn', 
        user: null,
        data: null
    })

    Dispatch.getAction(name, 'state')
        .subscribe(e => auth.update({ state: e.name, user: e.user, data: e.data }))

    auth.list.subscribe(e => Dispatch.nextState(name, e))
}
