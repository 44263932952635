import { merge} from 'rxjs';
import { filter, map, delay, startWith } from 'rxjs/operators';

export default (stateStream) => {
    const successStream$ = stateStream
        .pipe(
            filter(e => e === 'success' || e === true),
            map(e => 'success'),
            //delay(1500)
        )
    
    const errorStream$ = stateStream
        .pipe(
            filter(e => e === 'error' || e === false),
            map(e => 'error')
        )

    const noneStream$ = merge(successStream$, errorStream$.pipe(delay(2000)))
        .pipe(
            map(e => 'none')
        )

    return merge(successStream$, errorStream$, noneStream$)
        .pipe(
            startWith('pending'),
        )
}